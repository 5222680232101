@import url(./TableData.css);
@import url(./App.css);
@import url(./BillingView.css);
@import url(./BillingInvoice.css);

.side_bar_none{
  display: none;
}
.virtual_world-logo{
  width:60px;
  margin-top: 15px;
}
.main-container{
  position: relative;
  left:270px;
  width: 78%;
  background-color: white;
  overflow-y: hidden;
  /* overflow-x: hidden; */
  scrollbar-width: thin;
}
.billing-container{
  position: relative;
  left:270px;
  width: 82%;
  background-color: white;
  overflow: hidden;
  scrollbar-width: thin;
}
.salesViewHeadBlock{
  display: flex;
}
.salesViewFilterField{
  display: flex;
  width: 73%;
  justify-content: end;
}
.salesViewHeadSection{
  display: flex;
}
.BillingAddHeader{
  display: flex;
}
.billing-heading-viewblock{
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
}

/* .BillingAddAppendMainBlock{
  background: #F3F1F1;
  margin-top: 30px;
  margin-left: 15px;
  padding-left: 30px;
  border-radius: 5px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
 
} */
.billing-add-append-label-container{
  display: flex;
  width: 92% !important;
  justify-content: space-between;
  margin-top: 10px !important;
}
.BillingAddAppendField{
  background: #F3F1F1;
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  /* justify-content: space-around; */
  /* overflow: auto; */
  /* overflow-x: scroll; */
  /* flex-wrap: wrap; */
}
.BillingAddSelectOption{
  width: 120px;
  height: 40px;
  border-radius: 5px 0px 0px 0px;
  font-family: Rambla;
font-size: 22px;
font-weight: 700;
text-align: left;
background-color: white;
}

.billing_input_fields{
  width: 140px;
height: 57px ;
border: 1px solid #D3C5C5;
font-family: Rambla;
font-size: 22px;
font-weight: 400;
line-height: 26.93px;
margin-bottom: 20px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
padding-left: 20px;
}
.productAddProductField{
  width: 250px;
  height: 54.5px;
  border-radius: 5px 0px 0px 0px;
  border: 1px solid #D3C5C5;
  font-family: Rambla;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.93px;
  padding-left: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

}
.billingAddProductField{
/* max-width: 115px;
height: 40px;
border: 1px solid #D3C5C5;
font-family: Rambla;
font-size: 18px;
font-weight: 400;
line-height: 26.93px;
margin-bottom: 20px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
padding-left: 3px; */
margin-top: 10px;
    border: solid 1px gray;
    border-radius: 6px;
    font-size: 17px;
    padding: 10px 14px;
    width: 150px;
}
/* .--billingInputCss{
  margin-top: 10px;
    border: solid 1px gray;
    border-radius: 6px;
    font-size: 17px;
    padding: 10px 14px;
    width: 150px;
} */

.BillingAddInputContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
}
.BillingAddInputField{
  /* width: 400px; */
  margin: 10px;
padding: 8px;
display: flex;
flex-direction: column;
}
.purchaseAddInputField{
  /* width: 300px; */
  height: 57px;
  margin: 10px;
padding: 8px;
display: flex;
flex-direction: column;
}
.--customerInputCss{
/* background-color: black; */
width: 100%;

}
.BillingAddStatus{
  /* width: 250px;
  height: 54.5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
  margin-top: 10px;
    border: solid 1px gray;
    border-radius: 6px;
    font-size: 17px;
    padding: 10px 14px;
    width: 250px;
    color: gray;

}
.Billing-Add-GoBackBtn{
width: 60px;
height: 60px;
}
.Billng-Add-Heading{
  font-family: Rambla;
font-size: 48px;
font-weight: 700;
line-height: 58.75px;
} 
.Billing-Add-ViewBtn{
  width: 145px;
height: 42px;
border-radius: 10px 10px 10px 10px;
font-family: Inter;
font-size: 18px;
font-weight: 800;
color: white;
background-color: #06AE4B;
border: none;
}
.BillingAddInputLabel{
  /* font-family: Rambla;
font-size: 22px;
font-weight: 700;
line-height: 26.93px;
text-align: left;
color: black; */
font-family: 'Times New Roman', Times, serif;
margin-top: 10px;
font-size: 18px;
font-weight: 500;
text-align: left;
}
/* .BillingAddInputLabels{
  font-family: Rambla;
font-size: 22px;
font-weight: 700;
line-height: 25.93px;
text-align: left;
} */
.billing_input_field{
 width: 150px;
 height: 40px;
border: solid 1px gray;
border-radius: 6px;
font-size: 17px;
padding: 8px 14px;
outline: none;
}
.billing_input_field_select{
 width: 150px;
 height: 40px;
border: solid 1px gray;
border-radius: 6px;
font-size: 17px;
padding: 8px 14px;
outline: none;
}

.purchaseSelect_input_field{
 width: 350px;
 height: 40px;
/* margin-top: 10px; 
padding-left: 10px; */
border: solid 1px gray;
border-radius: 6px;
font-size: 17px;
/* padding-bottom: 10px; */
/* padding: 10px 14px; */
/* width: 200px; */
}
/* select option{
  background-image: url(attr(data-img));
} */

.BillingAdd-AddBtn{
  width: 45px;
height: 45px;
/* display: flex;
align-items: center; */
}
.BillingAddDeleteBtn{
  width: 20px;
  height: 20px;
}
.BillingAddSubmitBtn{
width: 100px;
height: 42px;
border-radius: 10px;
/* font-family: Inter; */
font-size: 18px;
font-weight: 600;
line-height: 21.78px;
color: white;
background-color: #06AE4B;
/* position: relative;
top:30px;
left:15px; */
}

.BillingAddFormBlock{
height: 85vh;
border-radius: 10px 10px 10px 10px;
/* border: 1px solid #D3C5C5; */
overflow-y:auto;
overflow-x: hidden;
scrollbar-width: thin;
box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;
/* padding:20px 0px; */
}
.PurchaseAddFormBlock{
height: 80vh;
border-radius: 10px 10px 10px 10px;
/* border: 1px solid #D3C5C5; */
overflow-y:auto;
/* box-shadow: 0 6px 2 0px 0 rgba(0, 0, 0, 0.19) ; */
}


.BillingAddAppendInputContainer{
  /* margin-top: 15px; */
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  padding: 10px 10px;

}
.barChartDateFiles{
  outline: none;
  background-color: transparent;
  border: 1px solid #2f5bfa;
  color: #2f5bfa;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}
.ghost_button{
  outline: none;
  background-color: transparent;
  border: 1px solid #2f5bfa;
  color: #2f5bfa;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}
.ghost_button:hover{
  outline: none;
  background-color:#2f5bfa;
  color: #fff;
}
.ghost_button_active{
  outline: none;
  border: none;
  border-radius: 5px;
  background-color:#2f5bfa;
  color: #fff;
}
/* Loading */
.wrapper .product_table_container_sticky {
overflow:hidden ;
}

.wrapper{
  width:200px;
  height:60px;
  position: absolute;
  /* left:0%;
  top:0%; */
  transform: translate(50%, 50%);
}
.circle{
  width:20px;
  height:20px;
  position: absolute;
  border-radius: 50%;
  background-color: #adadad7c;
  left:15%;
  transform-origin: 50%;
  animation: circle .5s alternate infinite ease;
}

@keyframes circle{
  0%{
      top:60px;
      height:5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
  }
  40%{
      height:20px;
      border-radius: 50%;
      transform: scaleX(1);
  }
  100%{
      top:0%;
  }
}
.circle:nth-child(2){
  left:45%;
  animation-delay: .2s;
}
.circle:nth-child(3){
  left:auto;
  right:15%;
  animation-delay: .3s;
}
.shadow{
  width:20px;
  height:4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  position: absolute;
  top:62px;
  transform-origin: 50%;
  z-index: -1;
  left:15%;
  filter: blur(1px);
  animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
  0%{
      transform: scaleX(1.5);
  }
  40%{
      transform: scaleX(1);
      opacity: .7;
  }
  100%{
      transform: scaleX(.2);
      opacity: .4;
  }
}
.shadow:nth-child(4){
  left: 45%;
  animation-delay: .2s
}
.shadow:nth-child(5){
  left:auto;
  right:15%;
  animation-delay: .3s;
}
.wrapper span{
  position: absolute;
  top:75px;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 12px;
  color: #000;
  left:15%;
}
.invoiceMainContainer{
  width: 100%;
  min-height: 100vh;
  /* border: 1px solid black; */
}
.vinmar_office_Type{
  color: black;
font-weight: 700;
}
.vinmar_heading{
color: black;
font-weight: 700;
font-size: 32px;
/* font-family: Rambla; */
/*  font-size */
}
.vinmarAddressField{
  display: flex;
  flex-direction: column;
}
.vinmar_address{
  font-weight: 500;
  color: black;
  font-size: 16px;
  /* font-family: Rambla; */
}
.vinmar_address_block{
  border-bottom: 3px dotted black;
}
.vinmar_ToData{
  font-weight: 500;
  color: black;
  font-size: 18px;
  /* font-family: Rambla; */
}
.vinmar_from{
  font-weight: 500;
  color: black;
  font-size: 16px;
}
.vinmar_gst{
  font-weight: 400;
  font-family: Rambla;
  font-size: 14px;
}
.vinmarTableData{
  text-align: center !important;
   border-right: 1px solid black !important; 
  border-bottom: none !important;
  font-weight: 700 !important;
  /* font-family: Rambla !important; */
}
.emptyTable{
  border-bottom: none !important;
}
.tableBorder{
  border: 1px solid black !important;
}
.vinmarTableHead{
  text-align: center !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  font-weight: 700 !important;
  /* font-family: Rambla !important; */
  font-size: 18px !important;
}
.challan_heading{
  font-weight: 700;
  font-family: Rambla;
  font-size: 18px;
  color: black;
  border-bottom: 1px solid black;
}
.tax_heading{
  font-weight: 700;
  font-family: Rambla;
  font-size: 24px;
  color: black;
  border-bottom: 1px solid black;
}
.tax_address_original{
  font-weight: 900;
  color: black;
  font-size: 16px;
  font-family: Rambla;
}
.tax_address_block{
  border-bottom: 1px solid black;
}